



















































































import './styles/ExecutiveDocumentAddingPopup.scss';
import './styles/ExecutiveDocumentAddingPopupAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IConstruct } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions } from '@store/modules/work-list/Types';
import { PopupActions } from '@store/Popup';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';

const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');

@Component({
    name: 'ExecutiveDocumentAddingPopup',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseFileInput: () => import('@components/BaseFileInput'),
    },
})
export default class ExecutiveDocumentAddingPopup extends Vue {
    $refs!: { basePopup: any }

    @NSProject.Getter('projectId') projectId!: number;

    @NSWorkList.Action(WorkListActions.A_ADD_FACT_TO_CONSTRUCT)
    dispatchAddExecutiveDocumentToConstruct!: (
        executiveDocumentAddingPayload: {
            startDate: any;
            endDate: any;
            taskDeadline: string;
            projectId: number;
            construct: IConstruct;
            fact: number;
            file: File;
        },
    ) => Promise<void>;

    @NSWorkList.Getter('actionOnConstruct') construct!: IConstruct;
    @NSWorkListPopups.Getter('executiveDocumentAddingPopupToggler')
    executiveDocumentAddingPopupToggler!: boolean;
    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    date = {
        start: '',
        end: '',
        deadline: '',
    };
    errors = {
        dateStart: false,
        dateEnd: false,
        fact: false,
        deadline: false,
        file: false,
    }
    fact = 0;
    files: File[] = [];
    realtimeValidation = false;
    btnDisabled = false;

    get limitsForDeadline() {
        if (this.date.end) {
            return { min: this.date.end, max: '9999-12-31' };
        }
        if (this.date.start) {
            return { min: this.date.start, max: '9999-12-31' };
        }
        return null;
    }

    get leftoversOfPlan() {
        return (this.construct.plan - this.construct.fact - Number(this.fact)).toFixed(2);
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get dateLimitsForWorksStart() {
        if (this.date.end) {
            return  { max: this.date.end }
        }
        return { max: '9999-12-31' };
    }

    get dateLimitsForWorksEnd() {
        if (this.date.start) {
            return  { min: this.date.start, max: '9999-12-31' }
        }
        return { max: '9999-12-31' };
    }

    @Watch('executiveDocumentAddingPopupToggler')
    watchPopupOpen() {
        if (this.executiveDocumentAddingPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    changeFiles(files: [File]) {
        this.files = files;
        this.checkValidation();
    }

    async addExecutiveDocumentToConstruct() {
        this.validateFields();
        if (!this.errorExists) {
            const dateStart = this.date.start.replace(/T\d{1,2}:\d{1,2}:\d{1,2}/g, '')+'T00:00:00';
            const dateEnd = this.date.end.replace(/T\d{1,2}:\d{1,22}:\d{1,2}/g, '')+'T00:00:00';
            const dateDeadline = this.date.deadline.replace(/T\d{1,2}:\d{1,22}:\d{1,2}/g, '')+'T00:00:00';
            this.dispatchAddExecutiveDocumentToConstruct({
                startDate: dateStart,
                endDate: dateEnd,
                taskDeadline: dateDeadline,
                projectId: this.projectId,
                construct: this.construct,
                fact: Number(this.fact),
                file: this.files[0],
            })
                .then(() => {
                    this.$emit('confirm-add-executive-document');
                    this.closePopup();
                });
        }
    }

    validateFields() {
        this.errors.dateStart = !this.date.start;
        this.errors.dateEnd = !this.date.end;
        this.errors.deadline = !this.date.deadline;
        this.errors.fact = !this.fact;
        this.errors.file = !this.files.length;
        this.realtimeValidation = true;
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'executiveDocumentAddingPopupToggler',
            state: false,
        });
        this.resetState();
    }

    resetState() {
        this.realtimeValidation = false;
        this.fact = 0;
        this.date = {
            start: '',
            end: '',
            deadline: '',
        };
        this.files = [];
        this.errors = {
            dateStart: false,
            dateEnd: false,
            deadline: false,
            file: false,
            fact: false,
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}
